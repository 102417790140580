// Generated by Framer (4175fb7)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nAV5sS0ox"];

const serializationHash = "framer-eapBU"

const variantClassNames = {nAV5sS0ox: "framer-v-uiggma"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nAV5sS0ox", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uiggma", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"nAV5sS0ox"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7V29yayBTYW5zLTUwMA==", "--framer-font-family": "\"Work Sans\", \"Work Sans Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1of0zx5, rgb(136, 136, 136))"}}>Go from design to site with Framer, the web builder for creative pros.</motion.h2></React.Fragment>} className={"framer-1f9193g"} fonts={["GF;Work Sans-500"]} layoutDependency={layoutDependency} layoutId={"HeUQfw_Zt"} style={{"--extracted-1of0zx5": "rgb(136, 136, 136)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eapBU.framer-yfel6f, .framer-eapBU .framer-yfel6f { display: block; }", ".framer-eapBU.framer-uiggma { height: 38px; overflow: hidden; position: relative; width: 390px; }", ".framer-eapBU .framer-1f9193g { flex: none; height: auto; left: 0px; max-width: 100%; overflow: visible; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 390
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAsIXw7ZUb: React.ComponentType<Props> = withCSS(Component, css, "framer-eapBU") as typeof Component;
export default FramerAsIXw7ZUb;

FramerAsIXw7ZUb.displayName = "icon";

FramerAsIXw7ZUb.defaultProps = {height: 38, width: 390};

addFonts(FramerAsIXw7ZUb, [{explicitInter: true, fonts: [{family: "Work Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNi0Dp6_cOyA.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})